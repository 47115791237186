.documents-wrapper {

    padding: 80px 0;

    .document-icon {
        max-width: 14px;
        max-height: 14px;
        margin-right: 5px;
    }

    .document-button {
        color: color('white') !important;

        i {
            margin-right: 5px;
        }

        @media(max-width: $media_md) {
            margin-bottom: 10px;
            display: block;
        }
    }
}