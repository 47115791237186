.team-section {
    color: color(text-dark);

    .team-title {
        text-align: left;
        font-size: 1.571rem;
        color: color(text-darker);
        font-weight: 600;
        text-transform: uppercase;
    }

    hr.text-separator {
        border-color: color(primary-clr);
        margin-left: 0;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .team-desc {

        font-weight: 400 !important;
        font-size: 1 !important;

        @include page-text();
    }


    .member-item {
        text-align: center;
        margin-bottom: 3rem;

        .profile-img-container {
            background-size:cover;
        }

        .profile-title {
            font-weight: 300;
            font-size: 1.714rem;
            color: color(text-darker);
            @media(max-width: $media_md){
                margin-top: 1rem;
            }
        }

        .text-separator {
            border-color: color(primary-clr);
            margin: 1.2rem auto;
        }

        .profile-desc {
            color: color(text-dark);
            font-weight: 400;
            font-size: 1rem;
            margin-bottom: 3rem;

            p {
                font-family: font(sub);
                font-weight: 300;
                font-size: 1rem;
            }
        }

        .profile-more {
            font-weight: 500;
            font-family: font(main);
            font-size: 1rem;
            text-decoration: none;
            padding: 5px 10px;
            color: color(text-darker);
            border: 1px solid #cccccc;
            transition: all .3s linear;

            &:hover {
                color: color(white-clr);
                background: color(primary-clr);
                border-color: color(primary-clr);
            }
        }


        &:nth-child(odd) {
            .order-first {
                -ms-flex-order: 13;
                order: 13;
            }

            .order-last {
                -ms-flex-order: -1;
                order: -1;
            }

            @media (max-width: $media_md) {
                .order-first {
                    -ms-flex-order: -1;
                    order: -1;
                }

                .order-last {
                    -ms-flex-order: 13;
                    order: 13;
                }
            }
        }

    }
}