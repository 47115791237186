.section {
  padding-top: 80px;
  padding-bottom: 80px;

  hr.section-separator {
    border: none;
    height: 3px;
    background: color('primary');
    color: color('primary');
    width: 100px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;

    &:before {
      content: '';
      height: 1px;
      color: color('primary');
      background: color('primary');
      width: 50px;
      display: block;
      position: absolute;
      left: -50px;
      top: 1px;
    }

    &:after {
      content: '';
      height: 1px;
      color: color('primary');
      background: color('primary');
      width: 50px;
      display: block;
      position: absolute;
      right: -50px;
      top: 1px;
    }

  }

  .section-title {
    font-family: font(main);
    font-size: 30px;
    font-weight: 300;
    color: color('text', 'dark');
    text-align: center;
    text-transform: uppercase;

    &h1 {
      font-size: 40px;
    }
  }

  .section-subtitle {
    font-family: font(main);
    font-weight: 300;
    font-size: 28px;
    color: color('text', 'dark');
    text-align: center;
    font-style: italic;
  }

  .section-text {
    font-family: font(sub);
    font-weight: 400;
    font-size: 15px;
    color: color('text', 'light');
    margin-top: 20px;
    margin-bottom: 20px;
    @include page-text();

  }

  .section-btn {
    margin: 0 auto;
    color: color('white') !important;
  }
}

#homepage {
  .section {
    padding-bottom: 40px;
  }
}