.our-team-detail-section {
    color: color(text-dark);

    .member-detail-title {
        text-align: left;
        font-size: 1.571rem;
        color: color(text-darker);
        font-weight: 600;
        text-transform: uppercase;
    }

    hr.text-separator {
        border-color: color(primary-clr);
        margin-left: 0;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .member-detail-desc {
        margin-bottom: 3rem;
        font-weight: 400;
        font-size: 1rem;

        @include page-text();
    }
}