.practices-section {
    color: color(text-dark);

    .column-2 {
        columns: 2;
        @media (max-width: $media_md) {
            columns: 1;
            margin: 0 auto;
        }
    }

    .practices-list {
        column-fill: auto;
        font-weight: 600;
        color: color(text-darker);

        .practice-item {
            font-size: 1rem;
            margin-bottom: 2rem;

            h2 {
                font-size: 1rem;
                margin-bottom: 2rem;
                font-weight: 600;
            }

            .practice {
                i {
                    color: color(primary-clr);
                    font-size: 1rem;
                    padding-right: 8px;
                    @include transition(all .3s linear);
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }

    }
}