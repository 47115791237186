.map-container {
    height: 400px;
    width: 100%;
    background: grey;
}

.find-us-btn {
    background-color: color('white');
    color: color('text', 'dark') !important;
    position: relative;
    left: 46%;
    bottom: -40px;
    font-weight: 700;
    text-transform: uppercase;
    border: rgba(color('text', 'dark'), 0.3) 1px solid;
    z-index: 2;

    &:hover {
        color: color('white') !important;
        background-color: color('text', 'dark');
    }
}

@media(max-width: $media_xl) {
    .find-us-btn {
        left: 43%;
    }
}

@media (max-width: $media_sm) {
    .find-us-btn {
        left: 40%;
    }
}

@media (max-width: $media_xs) {
    .find-us-btn {
        left: 30%;
    }
}